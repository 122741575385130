// i18next-extract-mark-ns-start klarna-alternative

import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {IndexBackground} from 'components/landings/IndexBackground';
import {List} from 'components/List';
import {
  ListRow,
  MobilePlanLabel,
  PlansContainer,
  PricingSection
} from 'components/pricing/PricingSection';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql, PageProps} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import logo from 'images/klarna.svg';
import selling from 'images/selling.svg';
import React from 'react';
import {HiCheck, HiOutlineX} from 'react-icons/all';
import styled from 'styled-components';
import {IndexImage} from 'components/landings/IndexImage';
import {BlogLink} from 'components/links/Blog';

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 90px;
  max-width: 515px;
`;

const PlanHeader = styled.div`
  padding: 60px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
`;

export const PlansTable = styled.div`
  position: relative;
  padding: 0 20px 0 50%;

  @media (max-width: 1024px) {
    padding: 0;
  }
`;

const Plan = styled.div`
  margin: -30px 10px 50px 10px;
  position: relative;
  z-index: 1;
  width: 250px;
  flex-shrink: 0;
  box-shadow: ${(props) => props.theme.boxShadow};
  background: #ffffff;
  border-radius: ${(props) => props.theme.borderRadius};

  @media (max-width: 768px) {
    width: 100%;
    margin: 50px 0 0;
  }
`;

const PricingContent = styled(Content)`
  padding-bottom: 100px;
`;

const PlanLabels = styled.ul`
  padding: 0;
  position: absolute;
  top: 150px;
  margin: 0;
  left: 0;
  list-style: none;
  width: 100%;

  li:after {
    display: block;
    content: '';
    width: 75%;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const PlanFeatures = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  ${ListRow} {
    justify-content: center;
  }
`;

export const PlansList = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 1024px) {
    justify-content: initial;
    overflow-x: scroll;
  }
`;

const YesIcon = styled(HiCheck)`
  font-size: 32px;
  color: green;
`;

const NoIcon = styled(HiOutlineX)`
  font-size: 32px;
  color: red;
`;

const Page: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="klarna-alternative"
        title={t('Klarna Alternative')}
        description={t(
          'Looking for the best alternative to Klarna? Accept all major payment methods in a single platform with MONEI’s payment gateway. Join now ››'
        )}
      />
      <IndexBackground sx={{left: {xl: '50%'}, height: {xl: '1000px'}, width: {xl: '2000px'}}}>
        <Content>
          <IndexContent>
            <SectionHeader underline tagName="h1">
              <Trans>Klarna Alternative</Trans>
            </SectionHeader>
            <Trans parent="p">
              Looking for a more affordable payment alternative to Klarna? Choose a{' '}
              <Link to="/">payment gateway</Link> like MONEI that will grow with your business.
            </Trans>
            <Trans parent="p">
              Enjoy MONEI’s evolutive pricing system: sell more and watch your variable fee
              decrease. Lower fixed fees plus this <Link to="/pricing/">tapering rate</Link> provide
              you with the best payment gateway rates on the market.
            </Trans>
            <SectionActions>
              <SignUpButton variant="light" style={{marginRight: 15}}>
                <Trans>Open an Account</Trans>
              </SignUpButton>
              <ContactSalesButton />
            </SectionActions>
            <IndexImage
              top="60px"
              width="700px"
              src={selling}
              alt="Klarna Alternative"
              title="Klarna Alternative"
              className="hide-on-mobile"
            />
          </IndexContent>
        </Content>
      </IndexBackground>
      <Background>
        <PricingContent>
          <Section centered>
            <div style={{maxWidth: 800}}>
              <Trans parent="p">
                The MONEI engineering team presented our solution for digital and e-commerce payment
                processing at{' '}
                <AnchorLink
                  href="https://aws.amazon.com/es/blogs/startups/serverless-architecture-powers-moneis-fast-secure-and-scalable-digital-payment-solutions/"
                  target="_blank"
                  title="Winner of the AWS’ Startup Architecture Challenge">
                  Amazon’s AWS Startup Architecture of the Year Program (Iberia region)
                </AnchorLink>{' '}
                — and we won! Our architecture is built with a focus on innovation, reliability, and
                user satisfaction.
              </Trans>
              <Trans parent="p">
                Offer your customers the option to choose from various payment methods via a{' '}
                <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                  3D Secure payment gateway
                </BlogLink>
                .
              </Trans>
              <Trans parent="p">
                There are several advantages — for all types of online merchants — to choosing MONEI
                over Klarna as your payment gateway alternative.
              </Trans>
              <List>
                <Trans parent="li">
                  Become a verified merchant, and enjoy our revolutionary 1-day payment settlement
                  system.
                </Trans>
                <Trans parent="li">
                  Get lower transaction fees, so you have more money to reinvest and grow your
                  business (Klarna’s fees can reach up to 4%, while ours are less than 1% for
                  European Credit Cards).
                </Trans>
                <Trans parent="li">
                  Protect yourself and your customers with 3D Secure Payments.
                </Trans>
              </List>
              <Trans parent="p">
                Use our API to seamlessly integrate MONEI with your e-commerce platform — you’ll be
                ready to sell in minutes
              </Trans>
              <Trans parent="p">
                You can also customize your store’s checkout appearance and use your custom domain.
                Customers will only see your branding during the checkout process, not ours.
              </Trans>
              <div style={{textAlign: 'center', marginBottom: 50}}>
                <SignUpButton variant="dark">
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </div>
            </div>
          </Section>
          {/* i18next-extract-mark-ns-stop klarna-alternative */}
          {/* i18next-extract-mark-ns-start alternatives */}
          <PricingSection>
            <PlansTable>
              <PlanLabels>
                <Trans parent={ListRow}>3D Secure transactions</Trans>
                <Trans parent={ListRow}>1-day payment settlement</Trans>
                <Trans parent={ListRow}>User-friendly APIs</Trans>
                <Trans parent={ListRow}>Mobile payments</Trans>
                <Trans parent={ListRow}>Hosted service</Trans>
                <Trans parent={ListRow}>Google Pay</Trans>
                <Trans parent={ListRow}>Apple Pay</Trans>
                <Trans parent={ListRow}>PayPal</Trans>
                <Trans parent={ListRow}>Recurring payments</Trans>
                <Trans parent={ListRow}>PCI DSS Level 1</Trans>
                <Trans parent={ListRow}>Free and multilingual onboarding and support</Trans>
                <Trans parent={ListRow}>
                  Integration with popular e-commerce platforms (Shopify, Wix, WooCommerce...)
                </Trans>
                <Trans parent={ListRow}>Multilingual dashboard</Trans>{' '}
                <Trans parent={ListRow}>Real-time control panel with advanced analytics</Trans>
                <Trans parent={ListRow}>
                  Multi-user capabilities with different access levels (admin or read-only)
                </Trans>
                <Trans parent={ListRow}>
                  All historical transaction data available (not only 12 months)
                </Trans>
                <Trans parent={ListRow}>Pay By Link (send a payment link via email or SMS)</Trans>
                <Trans parent={ListRow}>
                  Customize the appearance of the payment screen (logo, brand colors...)
                </Trans>
                <Trans parent={ListRow}>
                  Payment frame in your custom domain (the user always sees mysite.com)
                </Trans>
                <Trans parent={ListRow}>
                  On-site payments (no redirects), including Dynamic 3DS to improve conversion rates
                </Trans>
                <Trans parent={ListRow}>Real time credit card info formatting</Trans>
                <Trans parent={ListRow}>
                  Responsive design to adapt modules to the customer's screen
                </Trans>
                <Trans parent={ListRow}>
                  Customizable payment screen to offer a 100% integrated experience
                </Trans>
                <Trans parent={ListRow}>REST API connection for all kinds of integrations</Trans>
                <Trans parent={ListRow}>Payment methods tokenization</Trans>
                <Trans parent={ListRow}>
                  Webhooks to visualize all your clients/payments info from multiple sources (ERP,
                  CRM...)
                </Trans>
                <Trans parent={ListRow}>GraphQL API for data consumption</Trans>
                <Trans parent={ListRow}>
                  SMS and email notifications to clients for receipts, orders, and invoices
                </Trans>
                <Trans parent={ListRow}>
                  Smart routing engine sends payments sent to multiple acquirers to approve more
                  payments
                </Trans>
                <Trans parent={ListRow}>Full Direct Debit SEPA integration</Trans>
                <Trans parent={ListRow}>
                  Full white label solution for financial institutions and banks
                </Trans>
                <Trans parent={ListRow}>Combined acquiring and payment gateway solution</Trans>
                <Trans parent={ListRow}>
                  Transaction processing with new local payment methods like Bizum
                </Trans>
                <Trans parent={ListRow}>Installment payments</Trans>
                <Trans parent={ListRow}>
                  Dynamic billing — as you sell more, your transaction fees decrease
                </Trans>
              </PlanLabels>
              <PlansContainer>
                <PlansList>
                  <Plan>
                    <PlanHeader>
                      <img src="https://assets.monei.com/images/logo.svg" alt="MONEI" width={180} />
                    </PlanHeader>
                    <PlanFeatures>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>3D Secure transactions</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>1-day payment settlement</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>User-friendly APIs</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Mobile payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Hosted service</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Google Pay</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Apple Pay</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PayPal</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Recurring payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PCI DSS Level 1</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Free and multilingual onboarding and support
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Integration with popular e-commerce platforms (Shopify, Wix,
                          WooCommerce...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Multilingual dashboard</Trans>
                        <YesIcon />
                      </ListRow>{' '}
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Real-time control panel with advanced analytics
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Multi-user capabilities with different access levels (admin or read-only)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          All historical transaction data available (not only 12 months)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Pay By Link (send a payment link via email or SMS)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customize the appearance of the payment screen (logo, brand colors...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Payment frame in your custom domain (the user always sees mysite.com)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          On-site payments (no redirects), including Dynamic 3DS to improve
                          conversion rates
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Real time credit card info formatting
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Responsive design to adapt modules to the customer's screen
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customizable payment screen to offer a 100% integrated experience
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          REST API connection for all kinds of integrations
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Payment methods tokenization</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Webhooks to visualize all your clients/payments info from multiple sources
                          (ERP, CRM...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>GraphQL API for data consumption</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          SMS and email notifications to clients for receipts, orders, and invoices
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Smart routing engine sends payments sent to multiple acquirers to approve
                          more payments
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Full Direct Debit SEPA integration</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Full white label solution for financial institutions and banks
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Combined acquiring and payment gateway solution
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Transaction processing with new local payment methods like Bizum
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Installment payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Dynamic billing — as you sell more, your transaction fees decrease
                        </Trans>
                        <YesIcon />
                      </ListRow>
                    </PlanFeatures>
                  </Plan>
                  <Plan>
                    <PlanHeader>
                      <img src={logo} alt="Klarna" width={180} />
                    </PlanHeader>
                    <PlanFeatures>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>3D Secure transactions</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>1-day payment settlement</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>User-friendly APIs</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Mobile payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Hosted service</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Google Pay</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Apple Pay</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PayPal</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Recurring payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PCI DSS Level 1</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Free and multilingual onboarding and support
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Integration with popular e-commerce platforms (Shopify, Wix,
                          WooCommerce...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Multilingual dashboard</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Real-time control panel with advanced analytics
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Multi-user capabilities with different access levels (admin or read-only)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          All historical transaction data available (not only 12 months)
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Pay By Link (send a payment link via email or SMS)
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customize the appearance of the payment screen (logo, brand colors...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Payment frame in your custom domain (the user always sees mysite.com)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          On-site payments (no redirects), including Dynamic 3DS to improve
                          conversion rates
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Real time credit card info formatting
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Responsive design to adapt modules to the customer's screen
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customizable payment screen to offer a 100% integrated experience
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          REST API connection for all kinds of integrations
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Payment methods tokenization</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Webhooks to visualize all your clients/payments info from multiple sources
                          (ERP, CRM...)
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>GraphQL API for data consumption</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          SMS and email notifications to clients for receipts, orders, and invoices
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Smart routing engine sends payments sent to multiple acquirers to approve
                          more payments
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Full Direct Debit SEPA integration</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Full white label solution for financial institutions and banks
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Combined acquiring and payment gateway solution
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Transaction processing with new local payment methods like Bizum
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Installment payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Dynamic billing — as you sell more, your transaction fees decrease
                        </Trans>
                        <NoIcon />
                      </ListRow>
                    </PlanFeatures>
                  </Plan>
                </PlansList>
              </PlansContainer>
            </PlansTable>
          </PricingSection>
        </PricingContent>
      </Background>
    </>
  );
};

export default Page;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: {in: ["common", "klarna-alternative", "alternatives"]}
        language: {eq: $language}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
